//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { geneticAnalystIllustration, alertIcon } from "@debionetwork/ui-icons";
import { mapState } from "vuex";
export default {
  name: "GAUnverified",
  data: () => ({
    geneticAnalystIllustration,
    alertIcon
  }),
  computed: {
    ...mapState({
      GAAccount: state => state.substrate.GAAccount
    })
  }
};